const DATE_REG_EXP = /^(\d{1,2})([/-])(\d{1,2})([/-])(\d{4})\b/;

/**
 * Convert any value to a boolean. The string literal "false" converts to
 * `false`. All other string values coerce to `true`. For other types, use the
 * truthiness of the value.
 * @param val Any value to coerce.
 * @return A boolean value.
 */
export function coerceToBoolean(val: any): boolean {
  if (val === '') {
    return true;
  }

  return Boolean(val);
}

export function coerceToDate(value) {
  if (value instanceof Date) return value;

  // 11/20/2000
  const parts = DATE_REG_EXP.exec(value);
  if (parts && parts[2] === parts[4]) {
    return new Date(+parts[5], +parts[1] - 1, +parts[3]);
  }

  return new Date();
}

/**
 * Coerces the given `value` to a number using `parseFloat`.
 * If the parsed value is `NaN`, then 0 is returned.
 * @param value The value to coerce to a number.
 * @return A number value.
 */
export function coerceToNumber(value: unknown) {
  if (typeof value === 'number') {
    return value;
  }

  const val = parseFloat(value as any);
  return isNaN(val) ? 0 : val;
}

/**
 * A converter object that can be used to reflect booleans in a lit-element
 * component. Use as the value of the `converter` field of a property.
 */
export const booleanConverter = {
  fromAttribute(val) {
    return coerceToBoolean(val);
  },
  toAttribute(val) {
    val = coerceToBoolean(val);
    if (!val) {
      return null;
    }
    return '';
  },
};

/**
 * A converter object that can be used to reflect (date | string) in a lit-element
 * component. Use as the value of the `converter` field of a property.
 */
export const dateToStringConverter = {
  fromAttribute(val) {
    return val;
  },
  toAttribute(val) {
    if (val instanceof Date) {
      return val.toISOString();
    }
    return val;
  },
};
