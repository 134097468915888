
// These lines are multi-line copied from flag.ts enums.
const supportedFlags = [
  'ae',
  'al',
  'am',
  'at',
  'au',
  'ba',
  'be',
  'bg',
  'bh',
  'br',
  'by',
  'ca',
  'ch',
  'cl',
  'cn',
  'co',
  'cy',
  'cz',
  'de',
  'dk',
  'do',
  'ee',
  'eg',
  'es',
  'eu',
  'fi',
  'fr',
  'gb',
  'ge',
  'gr',
  'hk',
  'hr',
  'hu',
  'ie',
  'il',
  'in',
  'is',
  'it',
  'jo',
  'jp',
  'kp',
  'kr',
  'kw',
  'lb',
  'li',
  'lt',
  'lu',
  'lv',
  'ma',
  'md',
  'me',
  'mk',
  'mx',
  'my',
  'mt',
  'ng',
  'nl',
  'no',
  'nz',
  'om',
  'ph',
  'pl',
  'pt',
  'qa',
  'ro',
  'rs',
  'ru',
  'sa',
  'se',
  'sg',
  'si',
  'sk',
  'tr',
  'ua',
  'uk',
  'us',
  'xk',
  'za'
];
const NAME_TO_FLAG = {};
supportedFlags.forEach(flag => (NAME_TO_FLAG[`flag-${flag}`] = flag));

// CLOUD_FRONT_URL comes from webpack.DefinePlugin
export const getFlagUrl = (name: string) => {
  const flag = NAME_TO_FLAG[name.toLowerCase()];
  if (flag) {
    return `${CLOUD_FRONT_URL}/flags/${flag}.svg`;
  }

  // This ideally should not happen.
  // Currently, there are no cloudwatch 404 logs that indicate any teams are using unsupported flags.
  // This call is to log a console error as an early warning for parties that may use unsupported flags.
  // TODO - replace it with dev-time logging when https://sim.amazon.com/issues/KDS-2364 is ready
  console.error(`kat-icon: The flag ${name} is not supported.`);
};
