import { html, property } from 'lit-element';
import { KatLitElement, register } from '../../shared/base';

import baseStyles from '../../shared/base/base.lit.scss';
import styles from './spinner.lit.scss';

/**
 * @component {kat-spinner} KatalSpinner A spinner informs the user of the system status when an action with a short but unknown duration, such as loading or processing, is taking place in the background.<br /> This component is deprecated. Use <a href="/components/progress/">Progress component</a> instead.
 * @deprecated Use Progress instead.
 * @status Deprecated
 * @theme flo
 * @a11y {keyboard}
 * @a11y {sr}
 * @a11y {contrast}
 */
@register('kat-spinner')
export class KatSpinner extends KatLitElement {
  /**
   * The type of spinner to show
   * @enum {value} default The default spinner
   * @enum {value} inverted Inverted colors used for dark themes
   */
  @property()
  variant = 'default';

  /**
   * The size of the spinner to display. Defaults to large
   * @enum {value} small A smaller size spinner icon
   * @enum {value} large A larger size spinner icon.  This is the default size
   */
  @property()
  size = 'large';

  static get styles() {
    return [baseStyles, styles];
  }

  render() {
    return html`
      <svg viewBox="0 0 16 16">
        <path d="M8 3A5 5 0 1 1 3 8" stroke-linecap="round" />
      </svg>
    `;
  }
}
