import { html } from 'lit-element';
import { KatLitElement } from './kat-lit-element';

// https://stackoverflow.com/questions/74268235/set-lit-child-components-to-implicitly-target-lit-parent-component-slots
export class KatLitFilteredElement extends KatLitElement {
  public allowedTags?: string[];

  private _slot: string;

  private _allowedTags?: string[];

  constructor(slot: string, allowedTags?: string[]) {
    super();
    this._slot = slot;
    this._allowedTags = allowedTags;
  }

  // Setting these directly in the constructor will result in DOMException
  // https://stackoverflow.com/questions/59970043/custom-element-setup-constructor-vs-connectedcallback/59970158#59970158
  connectedCallback(): void {
    // Need to call super in order for "connected" event to be published
    super.connectedCallback();
    this.slot = this._slot;
    this.allowedTags = this._allowedTags;
  }

  // No need for nested ShadowDOM
  createRenderRoot(): Element | ShadowRoot {
    return this;
  }

  render() {
    if (this.allowedTags.length === 0) {
      return super.render();
    }

    const elements = this.allowedTags?.map(tag => this.querySelectorAll(tag));
    return html`${elements}`;
  }
}
