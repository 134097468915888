import {
  defaultConverter,
  LitElement,
  notEqual,
  PropertyDeclaration,
} from 'lit-element';
import {
  onHtmlDirChange,
  offHtmlDirChange,
  getHtmlDir,
} from '../../utils/rtl-utils';
// this import is mocked when building for the asset loader
import '../../metrics/register-component-metrics';
import { coerceToBoolean } from './type-utils';

type ListenerContext = [
  /** The name of the event. */
  string,
  /** The event handler. */
  (...args: any[]) => void,
  /** The event handler options. */
  (boolean | AddEventListenerOptions)?
];

type ListenerConfig =
  | ListenerContext
  | { target: HTMLElement | Window; listeners: ListenerContext[] };

const DEFAULT_REFLECT_ATTRIBUTES = [String, Number, Boolean];

const defaultPropertyDeclaration: PropertyDeclaration = {
  attribute: true,
  type: String,
  converter: defaultConverter,
  hasChanged: notEqual,
};

/**
 * Our extension of LitElement. Fires an event when the component is connected,
 * and adds additional useful methods.
 */
export class KatLitElement extends LitElement {
  /**
   * Listeners declared from the constructor by overriding this property.
   * `_listeners` is an array of two kinds of configs:
   *
   * 1. Events on this element are defined using an array where the elements
   *     correspond to the args of a `addEventListener` call. Listeners defined
   *     this way will be attached once in the component's `firstUpdated`
   *     method.
   *
   * 2. Events on other elements are defined using an object with a `target` and
   *    `listeners` field. The target is the element to attach events to, and
   *    listeners is an array of arrays, taking the same form as in #1.
   *    Listeners defined this way will be attached in `connectedCallback` and
   *    removed in `disconnectedCallback`.
   *
   * Using this to attach events declaratively prevents bugs where events are
   * not removed correctly due to bind calls when attaching.
   */
  protected _listeners: ListenerConfig[] = [];

  /**
   * SIM: https://sim.amazon.com/issues/KAT-6168
   */
  initialize() {
    super.initialize();

    if (
      this.renderRoot !== this &&
      !(this.renderRoot as any).adoptedStyleSheets?.length
    ) {
      this.adoptStyles();
    }
  }

  connectedCallback() {
    window.dispatchEvent(
      new CustomEvent('katal-component-connected', {
        detail: { element: this },
      })
    );

    this.__updateRtl(getHtmlDir());
    onHtmlDirChange(this.__updateRtl);

    super.connectedCallback();

    this._listeners.forEach(listener => {
      if (!Array.isArray(listener)) {
        listener.listeners.forEach(ctx => {
          listener.target.addEventListener(...ctx);
        });
      }
    });
  }

  disconnectedCallback() {
    offHtmlDirChange(this.__updateRtl);

    super.disconnectedCallback();

    this._listeners.forEach(listener => {
      if (!Array.isArray(listener)) {
        listener.listeners.forEach(ctx => {
          listener.target.removeEventListener(...ctx);
        });
      }
    });
  }

  dispatchCustomEvent<T extends Record<string, unknown>>(
    eventType: string,
    detail?: T,
    bubbles: EventInit | boolean = true
  ) {
    const evt = new CustomEvent(eventType, {
      detail,
      ...(typeof bubbles === 'boolean' ? { bubbles } : bubbles),
    });
    return this.dispatchEvent(evt);
  }

  firstUpdated() {
    this._listeners.forEach(ctx => {
      if (Array.isArray(ctx)) {
        this.addEventListener(...ctx);
      }
    });
  }

  observeChildren(callback: MutationCallback): MutationObserver;
  observeChildren(
    parent: HTMLElement,
    callback: MutationCallback
  ): MutationObserver;

  observeChildren(
    parentOrCallback: MutationCallback | HTMLElement,
    callback?: MutationCallback
  ): MutationObserver {
    let parent: HTMLElement;
    if (typeof parentOrCallback === 'function') {
      callback = parentOrCallback;
      parent = this;
    } else {
      parent = parentOrCallback;
    }

    // MutationObservers use weak maps to track the watched html element and
    // thus are garbage collected when that html element is garbage collected.
    // Therefore, there is no need to disconnect or keep track of active
    // mutation observers on an element.
    // @see https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver/disconnect#Usage_notes
    const observer = new MutationObserver(callback);
    observer.observe(parent, { childList: true });
    return observer;
  }

  // Override lit to default reflect to true for primitives
  // @see https://tiny.amazon.com/fl9zdvmm/createproperty
  static createProperty(
    name: PropertyKey,
    options: PropertyDeclaration = defaultPropertyDeclaration
  ) {
    const reflect =
      options.attribute !== false && shouldReflectType(options.type);
    // let the given option's reflect take precedence over the default
    super.createProperty(name, {
      reflect,
      ...options,
    });
  }

  // Override lit so we can coerce properties to the right types.
  // Lit only calls this if the class doesn't already have the descriptor defined.
  protected static getPropertyDescriptor(
    name: PropertyKey,
    key: string | symbol,
    options: PropertyDeclaration
  ) {
    const descriptor = super.getPropertyDescriptor(name, key, options);

    if (options.type === Boolean) {
      descriptor.set = createDescriptorSetter(name, key, coerceToBoolean);
    }

    return descriptor;
  }

  /**
   * Call `querySelector` on this element's `shadowRoot`.
   * @param selector A query selector.
   * @return The matching html element inside `shadowRoot`.
   */
  protected _shadow(selector: string) {
    return this.shadowRoot.querySelector(selector);
  }

  private __updateRtl = val => {
    if (!val) {
      this.removeAttribute('dir');
    } else {
      this.setAttribute('dir', val);
    }
  };
}

function createDescriptorSetter<T>(
  name: PropertyKey,
  key: string | symbol,
  coerce: (val: unknown) => T
) {
  return function (value: unknown) {
    const oldValue = this[name];
    this[key] = coerce(value);
    this.requestUpdate(name, oldValue);
  };
}

function shouldReflectType(type) {
  return type == null || DEFAULT_REFLECT_ATTRIBUTES.indexOf(type) !== -1;
}
