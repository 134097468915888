export enum KeyboardConstants {
  SPACE = ' ',
  ENTER = 'Enter',
  ARROW_UP = 'ArrowUp',
  ARROW_DOWN = 'ArrowDown',
  ARROW_LEFT = 'ArrowLeft',
  ARROW_RIGHT = 'ArrowRight',
  TAB = 'Tab',
  ESCAPE = 'Escape',
  SHIFT = 'Shift',
  CONTROL = 'Control',
  ALT = 'Alt',
  META = 'Meta',
  BACKSPACE = 'Backspace',
}

export const ConfirmAction = [KeyboardConstants.SPACE, KeyboardConstants.ENTER];
