import { Decorator } from '../../utils/decorators';

const KatalReady = (window.KatalReady = window.KatalReady || {});

/**
 * Fires a custom event with the given component name as the detail. Adds the
 * component name to the global `KatalReady` object.
 * @param componentName The name of the component that is ready.
 * @param eventType The name of the event to fire.
 */
export function fireReadyEvent(componentName: string, eventType: string) {
  if (KatalReady[componentName]) {
    return;
  }

  const event = new CustomEvent(eventType, {
    bubbles: true,
    detail: componentName,
  });

  KatalReady[componentName] = true;
  document.dispatchEvent(event);
}

export interface EventEmitter<T = void> {
  emit: (detail: T) => boolean;
}

/**
 * Decorate a class property to be turned into an `EventEmitter` object. The
 * decorated property should be given the `EventEmitter` type.
 *
 * @decorator
 * @param name The name of the event to fire.
 * @param options Customize the initialization of the fired event - bubbles,
 *     cancelable, etc.
 */
export const event =
  (name: string, options: EventInit | boolean = false): Decorator =>
  (descriptor): any => ({
    ...descriptor,
    initializer(this: HTMLElement): EventEmitter<any> {
      return {
        emit: (detail = {}) => {
          const evt = new CustomEvent(name, {
            detail,
            ...(typeof options === 'boolean' ? { bubbles: options } : options),
          });
          return this.dispatchEvent(evt);
        },
      };
    },
  });
