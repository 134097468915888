export function removeQueryString(url) {
  return /([^?]+)/.exec(url)[1];
}

export function recordTime(key) {
  if (window.Katal?.Performance) {
    const now = performance?.now ? performance.now() : Date.now();
    window.Katal.Performance[key] = now;
  }
}
