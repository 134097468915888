/**
 * This is an array that contins directional icons which exsit only on one direction.
 * Any icon exists in this array will be flipped horizontally in the RTL mode using 'rtl-icon' css class.
 */

export const iconsToFlipHorizantly = [
  'airport_shuttle',
  'announcement',
  'star-half',
  'assignment',
  'assignment_return',
  'chat',
  'chat-bubbles',
  'chat_bubble',
  'chat_bubble_outline',
  'chevron-right-bold',
  'comment',
  'content_cut',
  'directions_bike',
  'directions_run',
  'directions_walk',
  'dvr',
  'event_note',
  'exit_to_app',
  'feedback',
  'flight_land',
  'flight_takeoff',
  'flip',
  'flip_to_front',
  'flip_to_back',
  'format_list_bulleted',
  'format_list_numbered',
  'forum',
  'forward',
  'graph',
  'help',
  'help_outline',
  'input',
  'insert_comment',
  'keyboard_backspace',
  'keyboard_return',
  'keyboard_tab',
  'label',
  'label_outline',
  'launch',
  'list',
  'live_help',
  'local_shipping',
  'message',
  'messenger',
  'messenger_outline',
  'mms',
  'mode_comment',
  'more',
  'motorcycle',
  'next_week',
  'open_in_new',
  'paper-airplane',
  'question_answer',
  'reply',
  'reply_all',
  'return',
  'rotate_ninezero_degrees_ccw',
  'send',
  'share',
  'short_text',
  'show_chart',
  'signal_cellular_connected_no_internet_four_bar',
  'signal_cellular_four_bar',
  'signal_cellular_null',
  'smoking_rooms',
  'sms',
  'sms_failed',
  'sort_alt2',
  'speaker_notes',
  'speaker_notes_off',
  'subject',
  'textsms',
  'transfer_within_a_station',
  'trending_down',
  'trending_flat',
  'trending_up',
  'trending_neutral',
  'truck',
];
