const METHOD_NAME_PREFIX = 'KatalComponent';
const METRIC_KEY = 'totalConnectedOnPage';

let totalConnectedComponentCount = 0;
let individualConnectedComponentCounts = {};

/**
 * Entry point into the metric collection.
 */
export function collectComponentMetrics(publisher, sushiDriver) {
  sushiDriver.beforeUnload(() => {
    publishMetrics(publisher);
  });

  window.addEventListener('katal-component-connected', e => {
    const tag = e.detail.element.tagName.toLowerCase();
    incrementCounters(tag);
  });
}

/**
 * Increment the total count and individual count for the given element tag.
 * @param {string} tag The tag name of the connected component.
 */
function incrementCounters(tag) {
  ++totalConnectedComponentCount;

  const individualCount = individualConnectedComponentCounts[tag] || 0;
  individualConnectedComponentCounts[tag] = individualCount + 1;
}

/**
 * Publish the running counts of connected components using the given publisher.
 * @param publisher The metrics publisher to publish counts with.
 */
function publishMetrics(publisher) {
  const totalCountPublisher = publisher.newChildPublisher({
    methodName: `${METHOD_NAME_PREFIX}:all`,
  });
  totalCountPublisher.publishCounterMonitor(
    METRIC_KEY,
    totalConnectedComponentCount
  );

  const uniqueTagCountPublisher = publisher.newChildPublisher({
    methodName: `${METHOD_NAME_PREFIX}:uniqueTags`,
  });
  uniqueTagCountPublisher.publishCounterMonitor(
    METRIC_KEY,
    Object.keys(individualConnectedComponentCounts).length
  );

  for (const tag in individualConnectedComponentCounts) {
    const action = publisher.newChildPublisher({
      methodName: `${METHOD_NAME_PREFIX}:${tag}`,
    });
    action.publishCounterMonitor(
      METRIC_KEY,
      individualConnectedComponentCounts[tag]
    );
  }
}

/** For testing only. */
export function reset() {
  totalConnectedComponentCount = 0;
  individualConnectedComponentCounts = {};
}
