// This file is pulled from
// https://code.amazon.com/packages/KatalMetricsSellerCentral/trees/mainline

import * as KatalMetrics from '@amzn/katal-metrics';
import KatalMetricsDriverSushi from '@amzn/katal-metrics-driver-sushi';

const DEFAULT_SITE = 'SellerCentral';
const SC_PRODUCER = 'katal_sellercentral';

const SC_SOURCE_GROUPS: Record<string, string> = {
  test: 'com.amazon.eel.katal.metrics.core.nexus.sellercentral.gamma',
  prod: 'com.amazon.eel.katal.metrics.core.nexus.sellercentral',
};

export function makeContextBuilder(serviceName: string) {
  const contextBuilder = new KatalMetrics.Context.Builder();
  contextBuilder.withSite(DEFAULT_SITE);
  if (window.ue_id) {
    contextBuilder.withRelatedMetrics(
      new KatalMetrics.Metric.String('http_request_id', window.ue_id)
    );
  }

  // Can't auto-detect pageType/serviceName because ue_pty is set at the bottom of the page
  contextBuilder.withServiceName(serviceName);

  return contextBuilder;
}

export function makeDriverConfigBuilder(errorHandler: any) {
  const domainRealm = detectDomainRealm();

  // If we couldn't autodetect domain or realm, we already logged an error above
  // Now just use defaults, because some metrics are better than no metrics.
  const domain = domainRealm.domain || 'prod';
  const realm = domainRealm.realm || 'USAmazon';
  const builder = new KatalMetricsDriverSushi.Builder()
    .withCustomProducer(SC_PRODUCER)
    .withCustomSourceGroup(SC_SOURCE_GROUPS[domain])
    .withDomainRealm(domain, realm);

  builder.withErrorHandler(errorHandler);

  return builder;
}

const SUBDOMAIN_REALM_MAP: Record<string, string> = {
  na: 'USAmazon',
  eu: 'EUAmazon',
  fe: 'FEAmazon',
  cn: 'CNAmazon',
};

function detectDomainRealm() {
  const realm = detectRealmFromSurl();
  const domain = detectDomainFromSurl();

  return { domain, realm };
}

function detectDomainFromSurl() {
  const surl = window.ue_surl;
  if (surl) {
    if (surl.indexOf('nexusclient.prod') !== -1) {
      return 'prod';
    }
    if (surl.indexOf('nexusclient.gamma') !== -1) {
      return 'test';
    }
  }
}

function detectRealmFromSurl() {
  const surl = window.ue_surl;
  if (surl) {
    const subdomain = surl.match(/unagi[-]([a-z]{2})/);
    if (subdomain) {
      return SUBDOMAIN_REALM_MAP[subdomain[1]];
    }
  }
}
