import {
  convertWeblabMetaElementOnceWhenDomReady,
  attachVisualRefreshCSSOnceWhenDomReady,
} from '../../utils/weblab-helpers';
import { createMetricsPublisher } from '../../metrics/metrics-publisher';
import { fireReadyEvent } from './events';

export const register = (tagName: string) => descriptor => ({
  ...descriptor,
  finisher(clazz) {
    registerImpl(tagName, clazz);
  },
});

// exported for testing
export function registerImpl(tagName: string, clazz: any) {
  if (!window.customElements.get(tagName)) {
    try {
      convertWeblabMetaElementOnceWhenDomReady();
      attachVisualRefreshCSSOnceWhenDomReady();

      window.customElements.define(tagName, clazz);

      fireReadyEvent(tagName, 'katal-component-ready');
    } catch (e) {
      /**
       * https://w.amazon.com/bin/view/Katal/Operations/RunBook/#HRegistrationError
       *
       * In the event that component registration fails, spin up a KatalMetrics publisher and emit a metric.
       * The published metric will contain related string metrics that can be queried on Andes.
       *
       * KatalComponents loaded via the AssetLoader have an eventListener for errors that will send a log message
       * with more error detail, but we can't send logs from components consumed via direct dependency without
       * potentially introducing a CORS error on our consumers.
       * @see(src/metrics/metrics.js)
       */
      const { publisher } = createMetricsPublisher();
      publisher.publishCounterMonitor(
        `KatalInternalMetrics:registration-error:any`,
        1
      );
      publisher.publishCounterMonitor(
        `KatalInternalMetrics:registration-error:${tagName}`,
        1
      );
      console.error(`Unable to register ${tagName}`, e);
    }
  }
}
