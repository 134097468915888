export const NAME_TO_CODE_POINT = {
  ac_unit: 60219,
  access_alarm: 57744,
  access_alarms: 57745,
  access_time: 57746,
  accessibility: 59470,
  accessible: 59668,
  account_balance: 59471,
  account_balance_wallet: 59472,
  account_box: 59473,
  account_circle: 59475,
  adb: 58894,
  add: 57669,
  add_a_photo: 58425,
  add_alarm: 57747,
  add_alert: 60239,
  add_box: 57670,
  add_circle: 57671,
  add_circle_outline: 57672,
  add_location: 58727,
  add_shopping_cart: 59476,
  add_to_photos: 58269,
  add_to_queue: 60290,
  adjust: 58270,
  airline_seat_flat: 58928,
  airline_seat_flat_angled: 58929,
  airline_seat_individual_suite: 58930,
  airline_seat_legroom_extra: 58931,
  airline_seat_legroom_normal: 58932,
  airline_seat_legroom_reduced: 58933,
  airline_seat_recline_extra: 58934,
  airline_seat_recline_normal: 58935,
  airplanemode_active: 57749,
  airplanemode_inactive: 57748,
  airplanemode_off: 57748,
  airplanemode_on: 57749,
  airplay: 60283,
  airport_shuttle: 60220,
  alarm: 59477,
  alarm_add: 59478,
  alarm_off: 59479,
  alarm_on: 59480,
  album: 60240,
  alert: 57357,
  'alert-circle': 57400,
  'alert-circle-fill': 57399,
  'alert-fill': 57358,
  all_inclusive: 60221,
  all_out: 59659,
  android: 59481,
  announcement: 59482,
  aperture: 58287,
  apps: 58819,
  archive: 57673,
  'arrow-down': 57391,
  'arrow-left': 57421,
  'arrow-right': 57417,
  'arrow-up': 57376,
  arrow_back: 58820,
  arrow_downward: 58843,
  arrow_drop_down: 58821,
  arrow_drop_down_circle: 58822,
  arrow_drop_up: 58823,
  arrow_forward: 58824,
  arrow_upward: 58840,
  art_track: 60294,
  aspect_ratio: 59483,
  assessment: 59484,
  assignment: 59485,
  assignment_ind: 59486,
  assignment_late: 59487,
  assignment_return: 59488,
  assignment_returned: 59489,
  assignment_turned_in: 59490,
  assistant: 58271,
  assistant_photo: 58272,
  attach_file: 57894,
  attach_money: 57895,
  attachment: 58044,
  audiotrack: 58273,
  autorenew: 59491,
  av_timer: 60241,
  backspace: 57674,
  backup: 59492,
  barcode: 57352,
  battery_alert: 57756,
  battery_charging_full: 57763,
  battery_full: 57764,
  battery_std: 57765,
  battery_unknown: 57766,
  beach_access: 60222,
  beenhere: 58669,
  block: 57675,
  bluetooth: 57767,
  bluetooth_audio: 58895,
  bluetooth_connected: 57768,
  bluetooth_disabled: 57769,
  bluetooth_searching: 57770,
  blur_circular: 58274,
  blur_linear: 58275,
  blur_off: 58276,
  blur_on: 58277,
  book: 59493,
  bookmark: 59494,
  bookmark_border: 59495,
  bookmark_outline: 59495,
  border_all: 57896,
  border_bottom: 57897,
  border_clear: 57898,
  border_color: 57899,
  border_horizontal: 57900,
  border_inner: 57901,
  border_left: 57902,
  border_outer: 57903,
  border_right: 57904,
  border_style: 57905,
  border_top: 57906,
  border_vertical: 57907,
  box: 57369,
  branding_watermark: 57451,
  brightness_auto: 57771,
  brightness_five: 58282,
  brightness_four: 58281,
  brightness_high: 57772,
  brightness_low: 57773,
  brightness_medium: 57774,
  brightness_one: 58278,
  brightness_seven: 58284,
  brightness_six: 58283,
  brightness_three: 58280,
  brightness_two: 58279,
  broken_image: 58285,
  brush: 58286,
  bubble_chart: 59101,
  bug_report: 59496,
  build: 59497,
  burst_mode: 58428,
  business: 57519,
  business_center: 60223,
  cached: 59498,
  cake: 59369,
  calendar: 57408,
  'calendar-alt': 57383,
  call: 57520,
  call_end: 57521,
  call_made: 57522,
  call_merge: 57523,
  call_missed: 57524,
  call_missed_outgoing: 57572,
  call_received: 57525,
  call_split: 57526,
  call_to_action: 57452,
  camera: 57377,
  'camera-fill': 57378,
  camera_alt: 58288,
  camera_enhance: 59644,
  camera_filter: 58323,
  camera_front: 58289,
  camera_rear: 58290,
  camera_roll: 58291,
  cancel: 58825,
  card_giftcard: 59638,
  card_membership: 59639,
  card_travel: 59640,
  casino: 60224,
  cast: 58119,
  cast_connected: 58120,
  center_focus_strong: 58292,
  center_focus_weak: 58293,
  change_history: 59499,
  chat: 57527,
  'chat-bubbles': 57386,
  chat_bubble: 57546,
  chat_bubble_outline: 57547,
  check: 58826,
  check_box: 59444,
  check_box_outline_blank: 59445,
  check_circle: 59500,
  checkmark: 57350,
  'checkmark-circle': 57359,
  'checkmark-circle-fill': 57360,
  'chevron-down': 57346,
  'chevron-left': 57348,
  'chevron-right': 57349,
  'chevron-right-bold': 57411,
  'chevron-up': 57347,
  child_care: 60225,
  child_friendly: 60226,
  chrome_reader_mode: 59501,
  class: 59502,
  clear: 57676,
  clear_all: 57528,
  clock: 57367,
  'clock-fill': 57368,
  close: 58829,
  closed_caption: 60242,
  cloud: 58045,
  cloud_circle: 58046,
  cloud_done: 58047,
  cloud_download: 58048,
  cloud_off: 58049,
  cloud_queue: 58050,
  cloud_upload: 58051,
  code: 59503,
  collections: 58294,
  collections_bookmark: 58417,
  color_lens: 58295,
  colorize: 58296,
  comment: 57529,
  compare: 58297,
  compare_arrows: 59669,
  computer: 58122,
  confirmation_num: 58936,
  confirmation_number: 58936,
  contact_mail: 57552,
  contact_phone: 57551,
  contacts: 57530,
  content_copy: 57677,
  content_cut: 57678,
  content_paste: 57679,
  control_point: 58298,
  control_point_duplicate: 58299,
  copyright: 59660,
  create: 57680,
  create_new_folder: 58060,
  credit_card: 59504,
  crop: 58302,
  crop_din: 58305,
  crop_five_four: 58303,
  crop_free: 58306,
  crop_landscape: 58307,
  crop_onesix_nine: 58300,
  crop_original: 58308,
  crop_portrait: 58309,
  crop_rotate: 58423,
  crop_seven_five: 58304,
  crop_square: 58310,
  crop_three_two: 58301,
  danger: 57399, // "alert-circle-fill"
  dashboard: 59505,
  data_usage: 57775,
  date_range: 59670,
  dehaze: 58311,
  delete: 59506,
  delete_forever: 59691,
  delete_sweep: 57708,
  description: 59507,
  desktop_mac: 58123,
  desktop_windows: 58124,
  details: 58312,
  developer_board: 58125,
  developer_mode: 57776,
  device_hub: 58165,
  devices: 57777,
  devices_other: 58167,
  dialer_sip: 57531,
  dialpad: 57532,
  directions: 58670,
  directions_bike: 58671,
  directions_boat: 58674,
  directions_bus: 58672,
  directions_car: 58673,
  directions_ferry: 58674,
  directions_railway: 58676,
  directions_run: 58726,
  directions_subway: 58675,
  directions_train: 58676,
  directions_transit: 58677,
  directions_walk: 58678,
  disc_full: 58896,
  dnd_forwardslash: 58897,
  dns: 59509,
  do_not_disturb: 58898,
  do_not_disturb_alt: 58897,
  do_not_disturb_off: 58947,
  do_not_disturb_on: 58948,
  dock: 58126,
  domain: 59374,
  done: 59510,
  done_all: 59511,
  donut_large: 59671,
  donut_small: 59672,
  drafts: 57681,
  'drag-and-drop': 57433,
  drag_handle: 57949,
  drive_eta: 58899,
  dvr: 57778,
  edit: 58313,
  edit_location: 58728,
  eject: 59643,
  email: 57345,
  email_fill: 57534,
  emails: 57370,
  enhance_photo_translate: 59644,
  enhanced_encryption: 58943,
  equalizer: 60243,
  error: 57344,
  error_outline: 57400,
  euro_symbol: 59686,
  ev_station: 58733,
  event: 59512,
  event_available: 58900,
  event_busy: 58901,
  event_note: 58902,
  event_seat: 59651,
  exit: 57353, // "x"
  exit_to_app: 59513,
  expand_less: 58830,
  expand_more: 58831,
  explicit: 57374,
  explore: 59514,
  exposure: 58314,
  exposure_minus_one: 58315,
  exposure_minus_two: 58316,
  exposure_neg_one: 58315,
  exposure_neg_two: 58316,
  exposure_plus_one: 58317,
  exposure_plus_two: 58318,
  exposure_zero: 58319,
  extension: 59515,
  face: 59516,
  fast_forward: 60245,
  fast_rewind: 60246,
  favorite: 59517,
  favorite_border: 59518,
  favorite_outline: 59518,
  featured_play_list: 57453,
  featured_video: 57454,
  feedback: 59519,
  fiber_dvr: 60291,
  fiber_manual_record: 60295,
  fiber_new: 60292,
  fiber_pin: 57450,
  fiber_smart_record: 60296,
  file_download: 58052,
  file_upload: 58054,
  filter: 57387,
  filter_b_and_w: 58331,
  filter_center_focus: 58332,
  filter_drama: 58333,
  filter_eight: 58328,
  filter_five: 58325,
  filter_four: 58324,
  filter_frames: 58334,
  filter_hdr: 58335,
  filter_list: 57682,
  filter_nine: 58329,
  filter_nine_plus: 58330,
  filter_none: 58336,
  filter_one: 58320,
  filter_seven: 58327,
  filter_six: 58326,
  filter_three: 58322,
  filter_tilt_shift: 58338,
  filter_two: 58321,
  filter_vintage: 58339,
  find_in_page: 59520,
  find_replace: 59521,
  fingerprint: 59661,
  first_page: 58844,
  fitness_center: 60227,
  flag: 57683,
  flare: 58340,
  flash_auto: 58341,
  flash_off: 58342,
  flash_on: 58343,
  flight: 58681,
  flight_land: 59652,
  flight_takeoff: 59653,
  flip: 58344,
  flip_to_back: 59522,
  flip_to_front: 59523,
  folder: 58055,
  folder_open: 58056,
  folder_shared: 58057,
  folder_special: 58903,
  font_download: 57703,
  format_align_center: 57908,
  format_align_justify: 57909,
  format_align_left: 57910,
  format_align_right: 57911,
  format_bold: 57912,
  format_clear: 57913,
  format_color_fill: 57914,
  format_color_reset: 57915,
  format_color_text: 57916,
  format_indent_decrease: 57917,
  format_indent_increase: 57918,
  format_italic: 57919,
  format_line_spacing: 57920,
  format_list_bulleted: 57921,
  format_list_numbered: 57922,
  format_paint: 57923,
  format_quote: 57924,
  format_shapes: 57950,
  format_size: 57925,
  format_strikethrough: 57926,
  format_textdirection_l_to_r: 57927,
  format_textdirection_r_to_l: 57928,
  format_underline: 57929,
  format_underlined: 57929,
  forum: 57535,
  forward: 57684,
  forward_five: 60286,
  forward_onezero: 60284,
  forward_threezero: 60285,
  free_breakfast: 60228,
  fullscreen: 58832,
  fullscreen_exit: 58833,
  functions: 57930,
  g_translate: 59687,
  gamepad: 58127,
  games: 58127,
  gavel: 59662,
  gear: 57413,
  gesture: 57685,
  get_app: 59524,
  gif: 59656,
  globe: 57372,
  golf_course: 60229,
  gps_fixed: 57779,
  gps_not_fixed: 57780,
  gps_off: 57781,
  grade: 59525,
  gradient: 58345,
  grain: 58346,
  graph: 57385,
  graphic_eq: 57784,
  grid_off: 58347,
  grid_on: 58348,
  group: 59375,
  group_add: 59376,
  group_work: 59526,
  hamburger: 57409,
  hd: 60281,
  hdr_off: 58349,
  hdr_on: 58350,
  hdr_strong: 58353,
  hdr_weak: 58354,
  headset: 58128,
  headset_mic: 58129,
  healing: 58355,
  hearing: 60248,
  help: 59527,
  help_outline: 59645,
  high_quality: 60249,
  highlight: 57951,
  highlight_off: 59528,
  highlight_remove: 59528,
  hint: 59645, // "help_outline"
  history: 59529,
  home: 59530,
  horn: 57384,
  hot_tub: 60230,
  hotel: 58682,
  hourglass_empty: 59531,
  hourglass_full: 59532,
  http: 59650,
  https: 59533,
  image: 58356,
  image_aspect_ratio: 58357,
  import_contacts: 57568,
  import_export: 57539,
  important_devices: 59666,
  inbox: 57686,
  indeterminate_check_box: 59657,
  info: 57415,
  'info-circle': 57416,
  'info-circle-fill': 57418,
  info_outline: 59535,
  input: 59536,
  insert_chart: 57931,
  insert_comment: 57932,
  insert_drive_file: 57933,
  insert_emoticon: 57934,
  insert_invitation: 57935,
  insert_link: 57936,
  insert_photo: 57937,
  invert_colors: 59537,
  invert_colors_off: 57540,
  invert_colors_on: 59537,
  iso: 58358,
  keyboard: 58130,
  keyboard_arrow_down: 58131,
  keyboard_arrow_left: 58132,
  keyboard_arrow_right: 58133,
  keyboard_arrow_up: 58134,
  keyboard_backspace: 58135,
  keyboard_capslock: 58136,
  keyboard_control: 58835,
  keyboard_hide: 58138,
  keyboard_return: 58139,
  keyboard_tab: 58140,
  keyboard_voice: 58141,
  kitchen: 60231,
  label: 59538,
  label_outline: 59539,
  landscape: 58359,
  language: 59540,
  laptop: 58142,
  laptop_chromebook: 58143,
  laptop_mac: 58144,
  laptop_windows: 58145,
  last_page: 58845,
  launch: 59541,
  layers: 58683,
  layers_clear: 58684,
  leak_add: 58360,
  leak_remove: 58361,
  lens: 58362,
  library_add: 57404,
  library_books: 60256,
  library_music: 60257,
  lightbulb_outline: 59663,
  lightning: 57401,
  'lightning-fill': 57410,
  line_style: 59673,
  line_weight: 59674,
  linear_scale: 57952,
  link: 57687,
  linked_camera: 58424,
  list: 57424,
  live_help: 57542,
  live_tv: 58937,
  local_activity: 58687,
  local_airport: 58685,
  local_atm: 58686,
  local_attraction: 58687,
  local_bar: 58688,
  local_cafe: 58689,
  local_car_wash: 58690,
  local_convenience_store: 58691,
  local_dining: 58710,
  local_drink: 58692,
  local_florist: 58693,
  local_gas_station: 58694,
  local_grocery_store: 58695,
  local_hospital: 58696,
  local_hotel: 58697,
  local_laundry_service: 58698,
  local_library: 58699,
  local_mall: 58700,
  local_movies: 58701,
  local_offer: 58702,
  local_parking: 58703,
  local_pharmacy: 58704,
  local_phone: 58705,
  local_pizza: 58706,
  local_play: 58707,
  local_post_office: 58708,
  local_print_shop: 58709,
  local_printshop: 58709,
  local_restaurant: 58710,
  local_see: 58711,
  local_shipping: 58712,
  local_taxi: 58713,
  location_city: 59377,
  location_disabled: 57782,
  location_history: 58714,
  location_off: 57543,
  location_on: 57544,
  location_searching: 57783,
  lock: 59543,
  lock_open: 59544,
  lock_outline: 59545,
  looks: 58364,
  looks_five: 58366,
  looks_four: 58365,
  looks_one: 58368,
  looks_six: 58367,
  looks_three: 58363,
  looks_two: 58369,
  loop: 60250,
  loupe: 58370,
  low_priority: 57709,
  loyalty: 59546,
  mail: 57688,
  mail_outline: 57569,
  map: 58715,
  markunread: 57689,
  markunread_mailbox: 59547,
  memory: 58146,
  menu: 58834,
  merge_type: 57938,
  message: 57545,
  messenger: 57546,
  messenger_outline: 57547,
  mic: 60251,
  mic_none: 60252,
  mic_off: 60253,
  microphone: 57422,
  minus: 57381,
  'minus-circle': 57379,
  'minus-circle-fill': 57380,
  mms: 58904,
  mode_comment: 57939,
  mode_edit: 57940,
  monetization_on: 57955,
  money: 57373,
  money_off: 57948,
  monochrome_photos: 58371,
  mood: 59378,
  mood_bad: 59379,
  more: 58905,
  more_horiz: 58835,
  more_vert: 58836,
  motorcycle: 59675,
  mouse: 58147,
  move_to_inbox: 57704,
  movie: 60254,
  movie_creation: 58372,
  movie_filter: 58426,
  multiline_chart: 59103,
  multitrack_audio: 57784,
  music_note: 58373,
  music_video: 60297,
  my_library_add: 60255,
  my_library_books: 60256,
  my_library_music: 60257,
  my_location: 58716,
  nature: 58374,
  nature_people: 58375,
  navigate_before: 58376,
  navigate_next: 58377,
  navigation: 58717,
  near_me: 58729,
  network_cell: 57785,
  network_check: 58944,
  network_locked: 58906,
  network_wifi: 57786,
  new_releases: 57393,
  next_week: 57706,
  nfc: 57787,
  'no-reply': 57355,
  no_encryption: 58945,
  no_sim: 57548,
  not_interested: 60259,
  note: 57455,
  note_add: 59548,
  notifications: 59380,
  notifications_active: 59383,
  notifications_none: 59381,
  notifications_off: 59382,
  notifications_on: 59383,
  notifications_paused: 59384,
  now_wallpaper: 57788,
  now_widgets: 57789,
  'number-eight': 57442,
  'number-five': 57439,
  'number-four': 57438,
  'number-nine': 57443,
  'number-one': 57435,
  'number-seven': 57441,
  'number-six': 57440,
  'number-three': 57437,
  'number-two': 57436,
  offline_pin: 59658,
  ondemand_video: 58938,
  opacity: 59676,
  open_in_browser: 59549,
  open_in_new: 59550,
  open_with: 59551,
  pages: 59385,
  pageview: 59552,
  palette: 58378,
  pan_tool: 59685,
  panorama: 58379,
  panorama_fish_eye: 58380,
  panorama_fisheye: 58380,
  panorama_horizontal: 58381,
  panorama_vertical: 58382,
  panorama_wide_angle: 58383,
  paper: 57393,
  'paper-airplane': 57392,
  paperclip: 57412,
  party_mode: 59386,
  pause: 60260,
  pause_circle_filled: 60261,
  pause_circle_outline: 60262,
  payment: 59553,
  pencil: 57414,
  'pencil-circle': 57419,
  'pencil-circle-fill': 57420,
  people: 59387,
  people_outline: 59388,
  perm_camera_mic: 59554,
  perm_contact_cal: 59555,
  perm_contact_calendar: 59555,
  perm_data_setting: 59556,
  perm_device_info: 59557,
  perm_device_information: 59557,
  perm_identity: 59558,
  perm_media: 59559,
  perm_phone_msg: 59560,
  perm_scan_wifi: 59561,
  person: 59389,
  person_add: 59390,
  person_outline: 59391,
  person_pin: 58714,
  person_pin_circle: 58730,
  personal_video: 58939,
  pets: 59677,
  phone: 57549,
  phone_android: 58148,
  phone_bluetooth_speaker: 58907,
  phone_forwarded: 58908,
  phone_in_talk: 58909,
  phone_iphone: 58149,
  phone_locked: 58910,
  phone_missed: 58911,
  phone_paused: 58912,
  phonelink: 58150,
  phonelink_erase: 57563,
  phonelink_lock: 57564,
  phonelink_off: 58151,
  phonelink_ring: 57565,
  phonelink_setup: 57566,
  photo: 58384,
  photo_album: 58385,
  photo_camera: 58386,
  photo_filter: 58427,
  photo_library: 58387,
  photo_size_select_actual: 58418,
  photo_size_select_large: 58419,
  photo_size_select_small: 58420,
  picture: 57434,
  picture_as_pdf: 58389,
  picture_in_picture: 59562,
  picture_in_picture_alt: 59665,
  pie_chart: 59076,
  pie_chart_outlined: 59077,
  pin_drop: 58718,
  place: 58719,
  play: 57396,
  'play-fill': 57398,
  play_arrow: 60263,
  play_circle_fill: 60264,
  play_circle_filled: 60264,
  play_circle_outline: 60265,
  play_for_work: 59654,
  playlist_add: 57403,
  playlist_add_check: 57445,
  playlist_play: 60248,
  plus: 57364,
  'plus-circle': 57365,
  'plus-circle-fill': 57366,
  plus_one: 59392,
  poll: 59393,
  polymer: 59563,
  pool: 60232,
  portable_wifi_off: 57550,
  portrait: 58390,
  power: 58940,
  power_input: 58166,
  power_settings_new: 59564,
  pregnant_woman: 59678,
  present_to_all: 57567,
  'price-tag': 57351,
  'price-tag-fill': 57394,
  'price-tag-plus': 57431,
  print: 59565,
  'print-default': 57425,
  'print-left': 57426,
  'print-right': 57427,
  'print-with-receipt': 57428,
  priority_high: 58949,
  public: 59403,
  publish: 57941,
  query_builder: 59566,
  question_answer: 59567,
  queue: 57404,
  queue_music: 57405,
  queue_play_next: 57446,
  quick_contacts_dialer: 57551,
  quick_contacts_mail: 57552,
  radio: 57406,
  radio_button_checked: 59447,
  radio_button_off: 59446,
  radio_button_on: 59447,
  radio_button_unchecked: 59446,
  rate_review: 58720,
  receipt: 59568,
  recent_actors: 57407,
  record_voice_over: 59679,
  redeem: 59569,
  redo: 57690,
  refresh: 58837,
  remove: 57691,
  remove_circle: 57692,
  remove_circle_outline: 57693,
  remove_from_queue: 57447,
  remove_red_eye: 58391,
  remove_shopping_cart: 59688,
  reorder: 59646,
  repeat: 60301,
  repeat_one: 60266,
  replay: 60267,
  replay_five: 60289,
  replay_onezero: 60287,
  replay_threezero: 60288,
  reply: 57356,
  reply_all: 57695,
  report: 57696,
  report_problem: 59570,
  restaurant: 58732,
  restaurant_menu: 58721,
  restore: 59571,
  restore_page: 59689,
  return: 57395,
  ring_volume: 57553,
  room: 59572,
  room_service: 60233,
  rotate_left: 58393,
  rotate_ninezero_degrees_ccw: 58392,
  rotate_right: 58394,
  rounded_corner: 59680,
  router: 58152,
  rowing: 59681,
  rss_feed: 57573,
  rv_hookup: 58946,
  satellite: 58722,
  save: 57697,
  scanner: 58153,
  schedule: 59573,
  school: 59404,
  screen_lock_landscape: 57790,
  screen_lock_portrait: 57791,
  screen_lock_rotation: 57792,
  screen_rotation: 57793,
  screen_share: 57570,
  sd_card: 58915,
  sd_storage: 57794,
  search: 57389,
  'search-amazon': 57432,
  security: 58154,
  select_all: 57698,
  send: 57699,
  sentiment_dissatisfied: 59409,
  sentiment_neutral: 59410,
  sentiment_satisfied: 59411,
  sentiment_very_dissatisfied: 59412,
  sentiment_very_satisfied: 59413,
  settings: 59576,
  settings_applications: 59577,
  settings_backup_restore: 59578,
  settings_bluetooth: 59579,
  settings_brightness: 59581,
  settings_cell: 59580,
  settings_display: 59581,
  settings_ethernet: 59582,
  settings_input_antenna: 59583,
  settings_input_component: 59584,
  settings_input_composite: 59585,
  settings_input_hdmi: 59586,
  settings_input_svideo: 59587,
  settings_overscan: 59588,
  settings_phone: 59589,
  settings_power: 59590,
  settings_remote: 59591,
  settings_system_daydream: 57795,
  settings_voice: 59592,
  share: 59405,
  shipment: 57371,
  shop: 59593,
  shop_two: 59594,
  shopping_basket: 59595,
  shopping_cart: 59596,
  short_text: 57953,
  show_chart: 59105,
  shuffle: 60268,
  signal_cellular_connected_no_internet_four_bar: 57805,
  signal_cellular_four_bar: 57800,
  signal_cellular_no_sim: 57806,
  signal_cellular_null: 57807,
  signal_cellular_off: 57808,
  signal_wifi_four_bar: 57816,
  signal_wifi_four_bar_lock: 57817,
  signal_wifi_off: 57818,
  sim_card: 58155,
  sim_card_alert: 58916,
  skip_next: 60302,
  skip_previous: 60300,
  slideshow: 58395,
  slow_motion_video: 57448,
  smartphone: 58156,
  smoke_free: 60234,
  smoking_rooms: 60235,
  sms: 58917,
  sms_failed: 58918,
  snooze: 60267,
  sort: 57374,
  'sort-alt': 57375,
  sort_alt2: 57700,
  sort_by_alpha: 60282,
  spa: 60236,
  space_bar: 57942,
  speaker: 58157,
  speaker_group: 58158,
  speaker_notes: 59597,
  speaker_notes_off: 59690,
  speaker_phone: 57554,
  spellcheck: 59598,
  spinner: 57430,
  star: 57354,
  'star-fill': 57363,
  'star-half': 57423,
  star_border: 59450,
  star_outline: 59450,
  stars: 59600,
  stay_current_landscape: 57555,
  stay_current_portrait: 57556,
  stay_primary_landscape: 57557,
  stay_primary_portrait: 57558,
  stop: 60269,
  stop_screen_share: 57571,
  storage: 57819,
  store: 59601,
  store_mall_directory: 58723,
  straighten: 58396,
  streetview: 58734,
  strikethrough_s: 57943,
  style: 58397,
  subdirectory_arrow_left: 58841,
  subdirectory_arrow_right: 58842,
  subject: 59602,
  subscriptions: 60298,
  subtitles: 60270,
  subway: 58735,
  success: 57350, // "checkmark"
  supervisor_account: 59603,
  surround_sound: 60271,
  swap_calls: 57559,
  swap_horiz: 59604,
  swap_vert: 59605,
  swap_vert_circle: 59606,
  swap_vertical_circle: 59606,
  switch_camera: 58398,
  switch_video: 58399,
  sync: 58919,
  sync_disabled: 58920,
  sync_problem: 58921,
  system_update: 58922,
  system_update_alt: 59607,
  system_update_tv: 59607,
  tab: 59608,
  tab_unselected: 59609,
  tablet: 58159,
  tablet_android: 58160,
  tablet_mac: 58161,
  tag_faces: 58400,
  tap_and_play: 58923,
  terrain: 58724,
  text_fields: 57954,
  text_format: 57701,
  textsms: 57560,
  texture: 58401,
  theaters: 59610,
  threed_rotation: 59469,
  thumb_down: 59611,
  thumb_up: 59612,
  thumbs_up_down: 59613,
  time_to_leave: 58924,
  timelapse: 58402,
  timeline: 59682,
  timer: 58405,
  timer_off: 58406,
  timer_onezero: 58403,
  timer_three: 58404,
  title: 57956,
  toc: 59614,
  today: 59615,
  toll: 59616,
  tonality: 58407,
  touch_app: 59667,
  toys: 58162,
  track_changes: 59617,
  traffic: 58725,
  train: 58736,
  tram: 58737,
  transfer_within_a_station: 58738,
  transform: 58408,
  translate: 59618,
  trash: 57429,
  trending_down: 59619,
  trending_flat: 59620,
  trending_neutral: 59620,
  trending_up: 59621,
  truck: 57390,
  tune: 58409,
  turned_in: 59622,
  turned_in_not: 59623,
  tv: 58163,
  unarchive: 57705,
  undo: 57702,
  unfold_less: 58838,
  unfold_more: 58839,
  update: 59683,
  usb: 57824,
  verified_user: 59624,
  'vertical-dots': 57397,
  vertical_align_bottom: 57944,
  vertical_align_center: 57945,
  vertical_align_top: 57946,
  vibration: 58925,
  video_call: 57456,
  video_collection: 57418,
  video_label: 57457,
  video_library: 60272,
  videocam: 60273,
  videocam_off: 60274,
  videogame_asset: 58168,
  view_agenda: 59625,
  view_array: 59626,
  view_carousel: 59627,
  view_column: 59628,
  view_comfortable: 58410,
  view_comfy: 58410,
  view_compact: 58411,
  view_day: 59629,
  view_headline: 59630,
  view_list: 59631,
  view_module: 59632,
  view_quilt: 59633,
  view_stream: 59634,
  view_week: 59635,
  vignette: 58421,
  visibility: 59636,
  visibility_off: 59637,
  voice_chat: 58926,
  voicemail: 57561,
  volume_down: 60275,
  volume_mute: 60277,
  volume_off: 60278,
  volume_up: 60279,
  vpn_key: 57562,
  vpn_lock: 58927,
  wallet_giftcard: 59638,
  wallet_membership: 59639,
  wallet_travel: 59640,
  wallpaper: 57788,
  warning: 57358, // "alert-fill"
  watch: 58164,
  watch_later: 59684,
  wb_auto: 58412,
  wb_cloudy: 58413,
  wb_incandescent: 58414,
  wb_iridescent: 58422,
  wb_sunny: 58416,
  wc: 58941,
  web: 60280,
  web_asset: 57449,
  weekend: 57707,
  whatshot: 59406,
  widgets: 57789,
  wifi: 58942,
  wifi_lock: 57825,
  wifi_tethering: 57826,
  work: 59641,
  wrap_text: 57947,
  x: 57353,
  'x-circle': 57361,
  'x-circle-fill': 57362,
  youtube_searched_for: 59642,
  zoom_in: 59647,
  zoom_out: 59648,
  zoom_out_map: 58731,
};
