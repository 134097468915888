import * as KatalMetrics from '@amzn/katal-metrics';
import {
  makeContextBuilder,
  makeDriverConfigBuilder,
} from './metrics-seller-central';
import { removeQueryString } from './utils';

const SERVICE_NAME = 'KatalMetrics';

/**
 * @param sushiDriver An optional driver for testing.
 */
export function createMetricsPublisher(sushiDriver?: any) {
  const metricsErrorHandler = err => {
    console.warn(err);
  };

  let pageType = '';
  let subPageType = '';
  if (window.uept?.pageType) {
    pageType = window.uept.pageType;
    subPageType = window.uept.subPageType || '';
  }

  const contextBuilder = makeContextBuilder(SERVICE_NAME);

  // strip off the query string
  const url = removeQueryString(window.location.href);

  contextBuilder.addRelatedMetrics(
    ...[
      new KatalMetrics.Metric.String('url', url),
      new KatalMetrics.Metric.String('pageType', pageType),
      new KatalMetrics.Metric.String('subPageType', subPageType),
      new KatalMetrics.Metric.String('katalBuildTime', KATAL_BUILD_TIME),
      new KatalMetrics.Metric.String(
        'katalPackageVersion',
        BRAZIL_PACKAGE_VERSION
      ),
    ].map(s => {
      s.truncate = true;
      return s;
    })
  );

  if (!sushiDriver) {
    sushiDriver = makeDriverConfigBuilder(metricsErrorHandler).build();
  }

  const publisher = new KatalMetrics.Publisher(
    sushiDriver,
    metricsErrorHandler,
    contextBuilder.build()
  );

  return {
    publisher: publisher.newChildActionPublisherForMethod('Load'),
    sushiDriver,
  };
}
