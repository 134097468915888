/**
 * This file contains a dictionary for directional icons in the RTL mode.
 * All left icons points have been replaced with the right one and vice versa to get the correct icon in the RTL mode.
 * For example: The arrow-left point value is 57421 in the LTR mode which has been changed with the right one (57417) in the RTL mode.
 * Please keep this dictionary in your mind when you add a new directional icon in the name-to-code-point.js file.
 */

export const NAME_TO_CODE_POINT_FOR_RTL = {
  'arrow-left': 57417,
  'arrow-right': 57421,

  border_left: 57904,
  border_right: 57902,

  'chevron-left': 57349,
  'chevron-right': 57348,

  format_align_left: 57911,
  format_align_right: 57910,

  keyboard_arrow_left: 58133,
  keyboard_arrow_right: 58132,

  'print-left': 57427,
  'print-right': 57426,

  rotate_left: 58394,
  rotate_right: 58393,

  subdirectory_arrow_left: 58842,
  subdirectory_arrow_right: 58841,

  navigate_before: 58377,
  navigate_next: 58376,

  last_page: 58844,
  first_page: 58845,

  arrow_back: 58824,
  arrow_forward: 58820,

  format_indent_decrease: 57918,
  format_indent_increase: 57917,

  format_textdirection_l_to_r: 57928,
  format_textdirection_r_to_l: 57927,
};
