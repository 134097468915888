type DirChangeCallback = (dir: string) => void;

const htmlEl = document.documentElement;
const dirChangeCallbacks = new Set<DirChangeCallback>();
const dirChangeObserver = new MutationObserver(notifyDirChangeListeners);
let observing = false;

/**
 * Register a callback that will be called whenever html's `dir` attribute
 * changes. Called with the new value of `dir`.
 * @param cb
 */
export function onHtmlDirChange(cb: DirChangeCallback) {
  startObserver();
  dirChangeCallbacks.add(cb);
}

export function offHtmlDirChange(cb: DirChangeCallback) {
  dirChangeCallbacks.delete(cb);
}

export function getHtmlDir(): string {
  if (isPageDirectionForced()) {
    return '';
  }
  return htmlEl.dir;
}

export function isPageDirectionRightToLeft(): boolean {
  const dir = getHtmlDir();
  return dir.toLowerCase() === 'rtl';
}

function startObserver() {
  if (!observing) {
    observing = true;
    dirChangeObserver.observe(htmlEl, {
      // normally implied when using attributeFilter, but required by
      // non-chromium edge
      attributes: true,
      attributeFilter: ['dir'],
    });
  }
}

function notifyDirChangeListeners() {
  const htmlDir = getHtmlDir();
  dirChangeCallbacks.forEach(cb => {
    cb(htmlDir);
  });
}

// SIM: https://sim.amazon.com/issues/D14154064
const MONS_PATHS_TO_FORCE_PAGE_DIRECTION = [
  '/abis/index.html',
  'abis/listing',
  '/productclassify',
  '/productclassify-devo',
];

/**
 * Checks if the window href is a mons app path that should be forced to LTR direction.
 * SIM: https://sim.amazon.com/issues/D14154064
 * @returns true if the window.location.href should be forced to LTR direction.
 */
function isPageDirectionForced() {
  // https://t.corp.amazon.com/D24136944
  // This will be removed once Marathon has completed their weblab
  if (window.Katal?.forceRespectRtl) {
    return false;
  }

  const url = window.location.href;
  return MONS_PATHS_TO_FORCE_PAGE_DIRECTION.some(
    path => url.indexOf(path) > -1
  );
}
