import { createMetricsPublisher } from './metrics-publisher';
import { collectComponentMetrics } from './component-metrics';

/**
 * This module is meant only for the NPM dependency. By importing it, an event
 * handler is set up to listen for `katal-component-connected` and fires metrics
 * on page unload.
 */

const { publisher, sushiDriver } = createMetricsPublisher();
collectComponentMetrics(publisher, sushiDriver);
